import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import AutoComplete from 'primevue/autocomplete';
import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import ConfirmationService from 'primevue/confirmationservice'
import ConfirmDialog from 'primevue/confirmdialog'
import Dialog from 'primevue/dialog'
// import Editor from 'primevue/editor'
import Datepicker from 'vue3-date-time-picker'
import Dropdown from 'primevue/dropdown'
import Rating from 'primevue/rating';
import TreeSelect from 'primevue/treeselect'
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast'
import Tooltip from 'primevue/tooltip'; /* 220728 추가 */
import 'vue3-date-time-picker/dist/main.css'
import logger from './logger'
import VueSocialSharing from 'vue-social-sharing'
import vue3GoogleLogin from 'vue3-google-login'
import './registerServiceWorker'


// import VueSocketIO from 'vue-3-socket.io'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
//
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'primeicons/primeicons.css'
import '@/assets/css/reset.css';
import '@/assets/css/type.css';
// main scss build
import '@/assets/scss/main.scss'
// import { io } from "socket.io-client";
// import io from 'socket.io-client';

try {
    window.Kakao.init('70cb5d9954980497898c3841abcd9f97')
} catch (e) {
    console.error(['kakao error']);
}





const app = createApp(App)
// app.config.globalProperties.$soketio = io('http://localhost:3001', {});
app.config.globalProperties.$filters = {
    makeComma(value) {
        return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
}
app.component('AutoComplete', AutoComplete)
app.component('ConfirmDialog', ConfirmDialog)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Button', Button)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Dialog', Dialog)
// app.component('QuillEditor', Editor)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Datepicker', Datepicker)
app.component('DropDown', Dropdown)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Rating', Rating)
app.component('TreeSelect', TreeSelect)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Toast', Toast)
app.component('QuillEditor', QuillEditor)
app.directive('tooltip', Tooltip) /* 220728 추가 */

// let SOCKET_HOST = process.env.VUE_APP_API_URL;
//
// if (process.env.NODE_ENV === 'development') {
//     SOCKET_HOST = 'http://localhost:3001/';
// }

// const connection = io.connect('http://localhost:3002', { transports: ['websocket'], upgrade: false});
// app.use(VueSocketIO, new VueSocketIO({connection, debug:true}));

// app.use(new VueSocketIO({
//     debug: true,
//     connection: 'http://localhost:3001',
//     vuex: {
//         store,
//         actionPrefix: 'SOCKET_',
//         mutationPrefix: 'SOCKET_',
//     },
//     options: { cors: true }
// }));
app.use(vue3GoogleLogin, {
    clientId: '633358883531-pq7k6m09sibgijhp90thr1dj5eub9hfp.apps.googleusercontent.com'
})
app.use(router)
  .use(logger)
  .use(store)
  .use(VueCookies, {expireTimes: '2h', secure: true,})
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(ToastService)
  .use(VueSocialSharing)
  .mount('#wrapper');