<template>
  <app-header />
  <section id="container">
    <router-view />
  </section>
  <app-footer />
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
export default {
  name: "DefaultLayout",
  components: {AppFooter, AppHeader}
}
</script>

<style scoped>

</style>
