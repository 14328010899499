<template>
  <router-view />
  <Toast group="notification"/>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_PROJECTID + ".firebaseapp.com",
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_PROJECTID + ".appspot.com",
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID
};

let messaging = null;

if ('serviceWorker' in navigator) {
  // Initialize Firebase
  const fireApp = initializeApp(firebaseConfig);

  messaging = getMessaging(fireApp);

  getToken(messaging, {vapidKey: process.env.VUE_APP_VAPIKEY})
      .then((currentToken) => {
        console.log(['get token', currentToken]);
        if (currentToken) {
          const msgToken = localStorage.getItem('msgToken');
          console.log(['msgToken', msgToken]);
          if (!msgToken || msgToken !== currentToken) {
            localStorage.setItem('msgToken', currentToken);
          }
        } else {
          // Show permission request UI
          // console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch();
}

import { useToast } from "primevue/usetoast";
import { useCookies } from "vue3-cookies";


export default {
  name: 'App',
  components: {
  },
  setup() {
    const toast = useToast();
    const { cookies } = useCookies();

    if ('serviceWorker' in navigator) {
      onMessage(messaging, (payload) => {
        const token = cookies.get('accessToken');
        if (token) {
          const user = JSON.parse(localStorage.getItem('userInfo'));
          if (user.user_code === payload.data.receiver) {
            toast.add({severity:'info', summary: payload.notification.title, detail: payload.notification.body, life: 3000, group: 'notification'});
          }
        }
      });
    }
  },
}
</script>

<style>
</style>
