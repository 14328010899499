<template>
    <header>
        <div class="inner">
            <h1><router-link to="/"><img src="@/assets/common/logo-p.svg" alt="어썸멘토" /></router-link></h1>
            <nav v-if="mobile==false">
                <ul>
                    <li><router-link to="/activity" :class="menuActive === 'activity' ? 'active' : ''">활동검색</router-link></li>
                    <li><router-link to="/mentor" :class="menuActive === 'mentor' ? 'active' : ''">멘토매칭</router-link></li>
                    <li><router-link to="/ondemand" :class="menuActive === 'ondemand' ? 'active' : ''">온디맨드</router-link></li>
                </ul>
            </nav>
            <div class="right">
              <ul v-if="mobile==false&&login==false">
                <li><router-link to="/auth/login">로그인</router-link></li>
                <li><router-link to="/auth/sns">회원가입</router-link></li>
              </ul>
              <ul v-if="mobile==false&&login==true">
                <li><router-link to="" @click="logout">로그아웃</router-link></li>
                <li><router-link to="/mypage" :class="menuActive === 'mypage' ? 'active' : ''">마이페이지</router-link></li>
                <li v-if="userInfo.is_mentor"><router-link to="/mypage/mentorProfile">프로필</router-link></li>
                <li class="gnb_chatting"><router-link to="/mypage/chatting">채팅<span v-if="false">2</span></router-link></li>
              </ul>
              <div>
                <a v-if="login==true" class="btn_alarm" href="javascript:" :class="{ active: alarm, on: showAlarm }" @click="alarm=false; showAlarm=!showAlarm">
                  <img src="@/assets/common/ico-24-bell.svg" alt="알람">
                  <img src="@/assets/common/ico-24-bell-on.svg" alt="알람">
                </a>
                <a class="btn_search" href="javascript:" @click="showSearch=!showSearch">
                  <img src="@/assets/common/ico-24-search.svg" alt="검색">
                  <img src="@/assets/common/ico-24-close.svg" alt="닫기">
                </a>
                <a class="btn_menu" v-if="mobile" href="javascript:" @click="showMenu=!showMenu"><img src="@/assets/common/ico-24-gnb.svg" alt="메뉴"></a>
              </div>
            </div>
        </div>



        <div id="mobile_menu">
          <transition name="fade" mode="out-in">
            <div v-if="showMenu" @click="showMenu=false" class="black"></div>
          </transition>
          <transition name="slide-fade" mode="out-in">
            <div v-if="showMenu" class="mobile_menu_inner">
              <a class="btn_close_mobile_menu" href="javascript:" @click="showMenu=false"><img src="@/assets/common/ico-24-close.svg" alt="닫기"></a>
              <div class="top">
                <router-link to="/auth/login" v-if="login==false" @click="login=true">로그인하기<img src="@/assets/common/ico-22-arrow-b.svg" alt="링크"></router-link>
                <router-link to="/mypage" v-if="login==true"><span><img :src="viewPhoto(userInfo.user_photo)" alt="프로필 사진"></span>마이페이지<img src="@/assets/common/ico-22-arrow-b.svg" alt="링크"></router-link>
              </div>
              <div class="mid">
                <ul>
                  <li><router-link to="/activity">활동검색</router-link></li>
                  <li><router-link to="/mentor">멘토매칭</router-link></li>
                  <li><router-link to="/ondemand">온디맨드</router-link></li>
                </ul>
              </div>
              <div class="bottom">
                <ul v-if="login==false">
                  <li><router-link to="/auth/sns">회원가입</router-link></li>
                </ul>
                <ul v-else-if="login==true">
                  <li v-if="userInfo.is_mentor"><router-link to="/mypage/mentorProfile">프로필</router-link></li>
                  <li><router-link to="/mypage/chatting">채팅<span v-if="false">2</span></router-link></li>
                </ul>
              </div>
              <p class="btn_logout" v-if="login==true"><router-link to="" @click="logout">로그아웃</router-link></p>
            </div>
          </transition>
        </div>
        <transition name="fade" mode="out-in">
          <div id="header_alarm" v-if="showAlarm">
            <div class="title">
              <p>알림</p>
<!--              <router-link @click="goAlert">더보기</router-link>-->
              <a href="javascript:;" @click="goAlert">더보기</a>
            </div>
            <div class="list">
              <ul>
                <li v-for="(item, i) in alertList" :key="i" :class="item.is_read === 0 ? 'new' : ''">
                  <router-link :to="item.alert_path ? item.alert_path : ''">
                    <span></span>
                    <p>{{ item.alert_title }}</p>
                    <p>{{ item.alert_date }}</p>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </transition>

        <transition name="fade" mode="out-in">
          <div id="header_search" v-if="showSearch">
            <div>
              <input type="text" placeholder="검색어를 입력하세요." v-model="searchKeyword" @keydown="changeSearch" />
              <input type="button" value="검색" @click="totalSearch" />
            </div>
          </div>
        </transition>
    </header>
    <Toast position="center" group="search" />
</template>

<script>
import {getData} from "@/api/serverApi";

var mobile=0;
function w_resize(){
    if (window.innerWidth >= 1000) {
        mobile=0;
    } else {
        mobile=1;
    }
} w_resize();

export default {
  name: "AppHeader",
  data() {
    let IMG_HOST = process.env.VUE_APP_IMG_URL;

    if (process.env.NODE_ENV === 'development') {
      IMG_HOST = 'http://localhost:3001/';
    }
    return {
      IMG_HOST,
      CurrentRoute: this.$route.path,
      mobile,
      login: false,
      alarm: true,
      showSearch: false,
      showMenu: false,
      showAlarm: false,
      alertList: [],
      userInfo: {},
      searchKeyword: '',
      menuActive: '',
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAlert();
        this.checkLogin()
        this.showSearch=false;
        this.showMenu=false;
        this.showAlarm=false;
      }
      /*
      handler(to, from) {
          this.CurrentRoute=to.path;
      }
      */
    }
  },
  mounted() {
    // this.$socket.on('message', msg => {
    //   this.$log.debug(['chat message', msg]);
    // });
    window.addEventListener('resize', this.handleResize);
    this.$log.debug('header showSearch', this.showSearch, this.$route.path, this.$route.path.indexOf('activity'));

    // this.$soketio.on('message', msg => {
    //   this.$log.debug(['chat message', msg]);
    // });
  },
  /*
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  */
  methods: {
    async getAlert() {
      this.$log.debug(['getAlert', this.$route.path.indexOf('activity')])

      this.menuActive = 'abc';

      if (this.$route.path.indexOf('activity') > -1) {
        this.menuActive = 'activity';
      }

      if (this.$route.path.indexOf('mentor') > -1) {
        this.menuActive = 'mentor';
      }

      if (this.$route.path.indexOf('ondemand') > -1) {
        this.menuActive = 'ondemand';
      }

      if (this.$route.path.indexOf('mypage') > -1) {
        this.menuActive = 'mypage';
      }


      if (this.$cookies.get('accessToken')) {
        const res = await getData('/alert/topAlert', )
        this.alertList = res.data;
        if (this.alertList.length > 0) this.alarm = true;
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      }
    },
    goAlert() {
      this.$router.push('/mypage/alarm');
    },
    handleResize() {
      w_resize();
      return this.mobile = mobile;
    },
    checkLogin() {
      this.showSearch = false;
      this.login = !!this.$cookies.get('accessToken');
    },
    logout() {
      const user = JSON.parse(localStorage.getItem('userInfo'));
      this.$log.debug(['user', user, user.join_type]);
      if (user.join_type === 'naver') {
        // 네이버 로그아웃
        const naverLogin = new window.naver.LoginWithNaverId({
          clientId: 'v6uSeVLDQPjT2znR4Im1',
        });
        naverLogin.init();
        naverLogin.logout();
      } else if (user.join_type === 'kakao') {
        // 카카오 로그아웃
        window.Kakao.Auth.logout(() => {
          // console.log('logout');
        })

      }
      localStorage.removeItem('userInfo')
      this.$cookies.remove('isLoggedIn')
      this.$cookies.remove('accessToken')
      this.$cookies.remove('refreshToken')
      this.login = false;
      this.$router.push('/')
    },
    viewPhoto(photo) {
      let result = require('@/assets/data/thum-default.png');

      if (photo) {
        if (photo.indexOf('http') > -1) {
          result = photo;
        } else {
          result = this.IMG_HOST + photo;
        }
      }
      return result;
    },
    changeSearch(e) {
      if (e.keyCode === 13) {
        this.totalSearch();
      }
    },
    totalSearch() {
      this.$log.debug(['total search', this.searchKeyword])
      // 통합검색
      if (this.searchKeyword) {
        this.$log.debug(['searchKeyword', this.$r]);
        this.$router.push({name: 'totalSearch', params: { q: this.searchKeyword }}).then(() => {
          this.searchKeyword = '';
        })
      } else {
        this.$toast.add({severity:'error', detail:'검색어를 입력하세요.', life: 3000, group: 'search'});
      }
    }
  },
}
</script>

<style scoped>
</style>
