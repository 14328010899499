import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from "@/views/DefaultLayout";
import VueCookies from 'vue-cookies'
// import logger from '../logger'

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    redirect: '/mentor',
  },
  {
    path: '/membership',
    component: DefaultLayout,
    children: [{
      path: '',
      component:() => import('@/views/mypage/Membership'),
    }]
  },
  {
    path: '/search',
    component: DefaultLayout,
    children: [{
      path: '',
      name: 'totalSearch',
      component:() => import('@/views/common/Search'),
    }]
  },
  {
    path: '/sample',
    name: 'sample',
    component: () => import('@/views/sample/Sample')
  },
  {
    path: '/auth',
    component: DefaultLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/auth/Login')
      },
      {
        path: 'password',
        name: 'password',
        component: () => import('@/views/auth/Password')
      },
      {
        path: 'resetPassword',
        name: 'resetPassword',
        component: () => import('@/views/auth/ResetPassword')
      },
      {
        path: 'signUp',
        name: 'signUp',
        component: () => import('@/views/auth/SignUp')
      },
      {
        path: 'sns',
        name: 'sns',
        component: () => import('@/views/auth/SNSSignUp')
      },
      {
        path: 'naverCallback',
        name: 'naverCallback',
        component: () => import('@/views/auth/NaverCallback.vue')
      },
      {
        path: 'kakaoSignup',
        name: 'kakaoSignup',
        component: () => import('@/views/auth/KakaoSignup')
      },
      {
        path: 'welcome',
        name: 'welcome',
        component: () => import('@/views/auth/Welcome')
      },
    ],
  },
  {
    path: '/activity',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/activity/Index')
      },
      {
        path: ':activityCode',
        component: () => import('@/views/activity/Detail')
      },
      {
        path: 'detail',
        component: () => import('@/views/activity/Detail')
      },
      {
        name: 'activityAdd',
        path: 'add',
        component: () => import('@/views/activity/Add')
      },
      {
        name: 'activityEdit',
        path: 'edit/:activityCode',
        component: () => import('@/views/activity/Add')
      },
      {
        name: 'activitySearch',
        path: 'search',
        component: () => import('@/views/activity/Search')
      },
      {
        path: 'noResult',
        component: () => import('@/views/activity/NoResult')
      },

    ]
  },
  {
    path: '/mentor',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'mentorIndex',
        component: () => import('@/views/mentor/Index')
      },
      {
        name: 'mentorSearch',
        path: 'search',
        component: () => import('@/views/mentor/Search')
      },
      {
        path: ':serviceCode',
        component: () => import('@/views/mentor/Detail')
      },
      {
        path: 'detail',
        component: () => import('@/views/mentor/Detail')
      },
      {
        path: 'profile/:mentorCode',
        component: () => import('@/views/mentor/Profile')
      },
      {
        path: 'profile',
        component: () => import('@/views/mentor/Profile')
      },
      {
        name: 'chatting',
        path: 'chatting',
        component: () => import('@/views/mentor/Chatting')
      },
    ]
  },
  {
    path: '/mypage',
    component: DefaultLayout,
    beforeEnter: (to, from, next) => {
      if (VueCookies.get('accessToken')) {
        next();
      } else {
        next('/')
      }
    },
    children: [
      {
        path: '',
        name: 'mypageIndex',
        component: () => import('@/views/mypage/Index')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/mypage/Profile')
      },
      {
        path: 'secession',
        name: 'secession',
        component: () => import('@/views/mypage/Secession')
      },
      {
        path: 'mentor',
        name: 'mentor',
        component: () => import('@/views/mypage/Mentor')
      },
      {
        path: 'mentorProfile',
        name: 'mentorProfile',
        component: () => import('@/views/mypage/MentorProfile')
      },
      {
        path: 'addService',
        name: 'addService',
        component: () => import('@/views/mypage/AddService')
      },
      {
        path: 'editService',
        name: 'editService',
        component: () => import('@/views/mypage/EditService')
      },
      {
        path: 'service',
        name: 'service',
        component: () => import('@/views/mypage/Service')
      },
      {
        path: 'chatting',
        name: 'myChatting',
        component: () => import('@/views/mypage/Chatting')
      },
      {
        path: 'cash',
        name: 'cash',
        component: () => import('@/views/mypage/Cash')
      },
      {
        path: 'cashList',
        name: 'cashList',
        component: () => import('@/views/mypage/CashList')
      },
      {
        path: 'noData',
        component: import('@/views/mypage/NoData')
      },
      {
        path: 'activity',
        name: 'activity',
        component: () => import('@/views/mypage/Activity')
      },
      {
        path: 'review',
        name: 'review',
        component: () => import('@/views/mypage/Review')
      },
      {
        path: 'wishlist',
        name: 'wishlist',
        component: () => import('@/views/mypage/WishList')
      },
      {
        path: 'alert',
        name: 'alert',
        component: () => import('@/views/mypage/Alert')
      },
      {
        path: 'alarm',
        name: 'alarm',
        component: () => import('@/views/mypage/Alarm')
      },
      {
        path: 'membership',
        name: 'membership',
        component: () => import('@/views/mypage/Membership')
      },
      {
        path: 'complete',
        name: 'complete',
        component: () => import('@/views/mypage/Complete')
      },
      {
        path: 'subscribe',
        name: 'subscribe',
        component: () => import('@/views/mypage/Subscribe')
      },
      {
        path: 'alarmSetting',
        name: 'alarmSetting',
        component: () => import('@/views/mypage/AlarmSetting')
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import('@/views/mypage/Notice')
      },
      {
        path: 'notice/:seq',
        name: 'noticeView',
        component: () => import('@/views/mypage/NoticeView')
      },
      {
        path: 'qna',
        name: 'qna',
        component: () => import('@/views/mypage/QNA')
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/mypage/Contact')
      },
    ],
  },
  {
    path: '/ondemand',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/ondemand/Index')
      },
      {
        name: 'ondemandComplete',
        path: 'complete',
        component: () => import('@/views/ondemand/Complete')
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/common/Error')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to
                   , from, next) => {
  // logger.debug(['beforeEach', to, from, next])
  next();
})

export default router
