// import Vue from 'vue';
import axios from 'axios';
import VueCookies from 'vue-cookies'
// import store from '@/store/index'
let API_URL = process.env.VUE_APP_API_URL;

if (process.env.NODE_ENV === 'development') {
  // API_URL = 'https://testapi.2awesomementor.com/v1';
  API_URL = '/v1';
}

// const axiosInstance = axios.create({
//   baseURL: API_URL,
//   timeout: 3000,
// })

axios.interceptors.request.use( config => {
  // console.log(['axios config', config, Vue.$cookies, Vue.$cookies.get('access_token'), localStorage.getItem('refresh_token')]);
  // console.log(['axios config', config, VueCookies.get('accessToken')]);
  // const accessToken = store.getters["auth/getAccessToken"];
  const accessToken = VueCookies.get('accessToken');
  if (config.retry === undefined) {
    // config.url = store.state.login.
    // console.log(['config.retry', config.retry])
  }
  config.timeout = 10000;
  // config.headers['x-access-token'] = Vue.$cookies.get('access_token');
  config.headers['Content-Type'] = 'application/json';
  config.headers['Accept'] = 'application/json';
  if (accessToken) {
    config.headers['Authorization'] = 'Bearer ' + accessToken;
  }

  // config.headers.Authorization = 'Bearer ' + Vue.$cookies.get('access_token');
  return config;
}, error => {
  // console.log(['axios config error', error]);
  return Promise.reject(error);
});

axios.interceptors.response.use( res => {
  // console.log(['axios res', res]);
  try {
    return res;
  } catch (e) {
    // console.error(['axios.interceptors.response error', e]);
  }

}, async error => {
  console.log(['axios err', error]);
  try {
    const errorApi = error.response.config;
    const refreshToken = VueCookies.get('refreshToken');
    // const refreshToken = null;
    // console.log(['errorApi', errorApi, error, error.response.status, errorApi.retry, refreshToken]);
    // if (error.response.state == 401 && errorApi.retry === undefined && localStorage.getItem('refresh_token') != null) {
    if (error.response.status === 401 && errorApi.retry === undefined && refreshToken != null) {
      // console.log(['토큰발행', errorApi]);
      errorApi.retry = true;
      const param = {refreshToken: refreshToken};

      try {
        const res = await axios.post(API_URL + '/user/refreshToken', param);
        // console.log(['======= 토큰발행 res', res]);
        if (res.data.success) {
          VueCookies.set('accessToken', res.data.access_token, 60 * 60 * 2); // 2시간
        }
      } catch (e) {
        // console.error(['==== 토큰발행 err', e]);
      }

      // console.log(['refreshToken', param, errorApi]);
      // await store.dispatch('login/requestRefreshToken', param);
      return await axios(errorApi);
    }
  } catch (e) {
    // console.error(['response error', e]);
  }
  return Promise.reject(error);
})

// export async function fetchData(endPoint, params) {
//   let res = null;
//   try {
//     res = await axiosInstance.request({
//       url: endPoint,
//       method: ''
//     });
//     console.log(['postData', res]);
//   } catch (e) {
//     console.error(['postData error', e]);
//   }
//   return res;
// }

export async function postData(endPoint, params) {
  let res = null;
  try {
    // const headers = {
    //   'Content-type': 'multipart/form-data; charset=UTF-8',
    // }
    res = await axios.post(API_URL + endPoint, params);
  } catch (e) {
    console.error(['postData error', e]);
    res = e.response;
  }
  return res;
}

export async function getData(endPoint, params) {
  let res = null;
  try {
    // let query = '';

    // if (params) query = '?'+params

    res = await axios.get(API_URL + endPoint, {params: params});
  } catch (e) {
    console.error(['getData error', e]);
  }
  return res;
}

export async function deleteData(endPoint, params) {
  let res = null;
  try {
    res = await axios.delete(API_URL + endPoint, {data: params});
    console.log(['deleteData', res]);
  } catch (e) {
    // console.error(['deleteData error', e]);
  }
  return res;
}


export async function postBlobData(endPoint, params) {
  let res = null;
  try {
    res = await axios.post(API_URL + endPoint, params, { responseType: 'blob'});

  } catch (e) {
    console.error(['postBlobData error', e]);
  }
  return res;
}
