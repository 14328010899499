const state = () => ({
  username: null,
  connected: false,
  chatRooms: [],
  users: 0,
  messages: []
})

const getters = {
  Messages(state) {
    // console.log('store get messages');
    return state.messages;
  },
  Username(state) {
    return state.username;
  }
}

const actions = {
  setMessage(context, payload) {
    context.commit('setMessage', payload);
  },
  'SOCKET_message'({ commit, state }, message) {
    console.log(['SOCKET_message action', message, message.roomId, state.chatRooms ]);
    // if (message.roomId && message.roomId.filter(room => state.chatRooms.includes(room)).length > 0) {
    if (message.roomId && state.chatRooms.includes(message.roomId)) {
      console.log(['exist chat room']);
      commit('ADD_MESSAGE', message);
    }

    // commit('ADD_MESSAGE', message);


  },
  'SOCKET_users'({ commit }, users) {
    commit('UPDATE_USERS', users);
  },
  'SOCKET_join'({ commit }, nickname) {
    commit('ADD_MESSAGE', {
      username: nickname,
      content: 'Joined...'
    })
  },
  'SOCKET_triggerRest'({ commit }) {
    commit('ADD_MESSAGE', {
      username: 'REST',
      content: 'Trigger by REST API'
    })
  },
  'SOCKET_connect'({ commit }) {
    commit('SET_CONNECTED', true);

  },
  'SOCKET_disconnect'({ commit }) {
    commit('SET_CONNECTED', false);
  },
  'SOCKET_connect_error'({ commit }) {
    console.log(['SOCKET_connect_error', commit]);
    commit('SET_CONNECTED', false);
  }
}

const mutations = {
  setMessage(state, payload) {
    state.messages = payload;
  },
  ADD_MESSAGE(state, message) {
    console.log(['chat mutations', message]);
    state.messages.push(message);
  },
  UPDATE_USERS(state, users) {
    console.log(['UPDATE_USERS', users]);
    state.users = users;
  },
  ADD_CHATID(state, chatID) {
    console.log(['ADD_CHATID mutations', state.chatRooms, chatID, state.chatRooms.includes(chatID)]);
    if (!state.chatRooms.includes(chatID)) state.chatRooms.push(chatID);

  },
  SET_USERNAME(state, nickname) {
    state.username = nickname;
    // router.replace({name: 'live-chat'});
  },
  SET_CONNECTED(state, flag) {
    console.log(['socket connected']);
    state.connected = flag;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
